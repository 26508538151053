/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  Fragment,
  lazy,
  useEffect,
  useState,
  useCallback,
} from "react";
import { Box, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import { observer } from "mobx-react-lite";
import { ChevronRight, LocationOn } from "@mui/icons-material";
import { APIProvider } from "@vis.gl/react-google-maps";
import { usePageContext } from "@root/renderer/usePageContext";
import { useAccountContext } from "@root/stores/accountStore";
import { useEventPageContext } from "@root/stores/eventPageStore";
import { accountService } from "@root/services/api/accountService";
import { eventService } from "@root/services/api/eventService";
import { organizationService } from "@root/services/api/organizationService";
import { hasErrors } from "@root/utils/functions";
import { Event, Organization } from "@root/utils/entities";
import { StyledChip } from "@root/components/common/styled";
import { EventList } from "@root/components/EventList";
import { LoadingSpinner } from "@root/components/common/LoadingSpinner";
import { navigate } from "vike/client/router";
import { useMapSetup } from "@root/hooks/useMapSetup";
import { LoadingOverlay } from "@root/components/common/LoadingOverlay";

const Map = lazy(() =>
  import("./components/Map").then((module) => ({ default: module.Map }))
);
const ViewEventModal = lazy(() =>
  import("@root/components/modals/ViewEventModal").then((module) => ({
    default: module.ViewEventModal,
  }))
);
const ViewOrganizationModal = lazy(() =>
  import("@root/components/modals/ViewOrganizationModal").then((module) => ({
    default: module.ViewOrganizationModal,
  }))
);
const MobileEventView = lazy(() =>
  import("@root/components/MobileEventView").then((module) => ({
    default: module.MobileEventView,
  }))
);
const MobileOrganizationView = lazy(() =>
  import("@root/components/MobileOrganizationView").then((module) => ({
    default: module.MobileOrganizationView,
  }))
);

export const Page: React.FC = observer(() => {
  const pageContext = usePageContext();
  const accountStore = useAccountContext();
  const store = useEventPageContext();

  useMapSetup(store, accountStore);

  useEffect(() => {
    const handleTokenVerification = async (token: string) => {
      store.showLoading();
      try {
        const response = await accountService.verifyToken(token);
        if (response.data) {
          await accountStore.setAccountAndSession(response.data);
          if (pageContext.urlParsed.search.orgId) {
            accountStore.switchToOrgProfile(pageContext.urlParsed.search.orgId);
          }
          window.location.href = pageContext.urlParsed.search.redir || "/";
        }
      } catch (error) {
        console.error("Token verification error:", error);
      } finally {
        store.hideLoading();
      }
    };

    const loadEvent = async (id: string) => {
      store.showLoading();

      const response = await eventService.getEvent(id);

      if (hasErrors(response)) {
        window.location.href = "/";
      } else {
        store.showEvent(response.data!.event);
      }

      store.hideLoading();
    };

    const loadOrganization = async (id: string) => {
      store.showLoading();

      const response = await organizationService.getOrganization(id);

      if (hasErrors(response)) {
        await navigate("/", { overwriteLastHistoryEntry: true });
      } else {
        store.showOrganization(response.data!.organization);
      }

      store.hideLoading();
    };

    const { token, promptLogin, event, organization } =
      pageContext.urlParsed.search;

    if (token) handleTokenVerification(token);
    else if (promptLogin) {
      accountStore.authModalStore.openLoginModal(
        pageContext.urlParsed.search.redir || "/"
      );
    }

    if (event) loadEvent(event);
    if (organization) loadOrganization(organization);
  }, [pageContext, store, accountStore]);

  return (
    <APIProvider apiKey={import.meta.env.PUBLIC_ENV__GOOGLE_MAPS_API_KEY}>
      <EventPage />
    </APIProvider>
  );
});

const EventPage: React.FC = observer(() => {
  const eventPageStore = useEventPageContext();
  const theme = useTheme();
  const mediumScreen = useMediaQuery(theme.breakpoints.down("md"));

  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    if (!mounted) setMounted(true);
  }, [mounted]);

  if (!mounted) return null;

  return (
    <>
      <LoadingOverlay show={eventPageStore.isLoading} />
      {mediumScreen ? <MobileView /> : <WebView />}
    </>
  );
});

const WebView: React.FC = observer(() => {
  const eventPageStore = useEventPageContext();

  return (
    <Fragment>
      <EventGrid />
      <React.Suspense fallback={<LoadingSpinner />}>
        {eventPageStore.currentSubview === "organization" && (
          <ViewOrganizationModal
            store={eventPageStore.orgModalStore}
            organization={eventPageStore.organization}
            onClose={() => eventPageStore.hideOrganization()}
            onViewEvent={(event: Event) => eventPageStore.showEvent(event)}
          />
        )}
        {eventPageStore.currentSubview === "event" && (
          <ViewEventModal
            event={eventPageStore.event}
            store={eventPageStore.viewModalStore}
            onClose={() => eventPageStore.hideEvent()}
            viewOrganization={(organization: Organization) =>
              eventPageStore.showOrganization(organization)
            }
          />
        )}
      </React.Suspense>
    </Fragment>
  );
});

const EventGrid: React.FC = observer(() => {
  const accountStore = useAccountContext();
  const eventPageStore = useEventPageContext();

  const onPageChange = useCallback(async () => {
    await eventPageStore.searchEvents(accountStore.userLocation);
  }, [accountStore.userLocation]);

  if (!eventPageStore.events) return null;

  return (
    <Grid container sx={{ height: "100%", flexWrap: "nowrap" }}>
      <Grid item xs={3} sx={{ overflowY: "auto", padding: "1rem" }}>
        {eventPageStore.events.length > 0 ? (
          <EventList
            events={eventPageStore.events}
            paginationStore={eventPageStore.paginationStore}
            onPageChange={onPageChange}
            onViewEvent={(event) => eventPageStore.showEvent(event)}
          />
        ) : (
          <EmptyResults />
        )}
      </Grid>
      <Grid item xs={9} sx={{ border: "1px solid transparent" }}>
        {accountStore.locationRequested && (
          <React.Suspense fallback={<LoadingSpinner />}>
            <Map />
          </React.Suspense>
        )}
      </Grid>
    </Grid>
  );
});

const MobileView: React.FC = observer(() => {
  const accountStore = useAccountContext();
  const eventPageStore = useEventPageContext();

  const onPageChange = useCallback(async () => {
    await eventPageStore.searchEvents(accountStore.userLocation);
  }, [accountStore.userLocation]);

  return (
    <Fragment>
      {eventPageStore.currentSubview === "organization" ? (
        <React.Suspense fallback={<LoadingSpinner />}>
          <MobileOrganizationView
            organization={eventPageStore.organization!}
            onClose={() => eventPageStore.hideOrganization()}
            onViewOrganization={(organization) =>
              eventPageStore.showOrganization(organization)
            }
            onViewEvent={(event: Event) => eventPageStore.showEvent(event)}
          />
        </React.Suspense>
      ) : eventPageStore.currentSubview === "event" ? (
        <React.Suspense fallback={<LoadingSpinner />}>
          <MobileEventView
            event={eventPageStore.event!}
            onOrganizationClick={(organization) =>
              eventPageStore.showOrganization(organization)
            }
            onClose={() => eventPageStore.hideEvent()}
          />
        </React.Suspense>
      ) : (
        <></>
      )}
      <Grid
        container
        sx={{
          padding: 0,
          display: eventPageStore.currentSubview ? "none" : "grid",
          gap: 0,
          flexGrow: 1,
          height: "100%",
        }}
      >
        <Grid item xs={12}>
          <Box
            sx={{
              padding: "1rem 1rem 3rem 1rem",
              display: eventPageStore.viewMode === "list" ? "flex" : "none",
            }}
          >
            {eventPageStore.events && eventPageStore.events!.length > 0 ? (
              <EventList
                events={eventPageStore.events ?? []}
                paginationStore={eventPageStore.paginationStore}
                onPageChange={onPageChange}
                onViewEvent={(event) => eventPageStore.showEvent(event)}
              />
            ) : (
              <EmptyResults />
            )}
          </Box>
          {accountStore.locationRequested && (
            <React.Suspense>
              <Map />
            </React.Suspense>
          )}
          <MobileToggleButtons />
        </Grid>
      </Grid>
    </Fragment>
  );
});

const MobileToggleButtons = observer(() => {
  const eventPageStore = useEventPageContext();

  return (
    <Box
      sx={{
        position: "fixed",
        bottom: "1rem",
        display: "flex",
        justifyContent: "center",
        width: "100%",
      }}
    >
      <StyledChip
        onClick={(e) => {
          e.preventDefault();
          if (eventPageStore.viewMode === "list") {
            eventPageStore.showMap();
          } else {
            eventPageStore.showList();
          }
        }}
        variant="outlined"
        label={eventPageStore.viewMode === "list" ? "View Map" : "View List"}
        icon={
          eventPageStore.viewMode === "list" ? (
            <LocationOn sx={{ width: "auto", height: "1.25rem" }} />
          ) : (
            <ChevronRight sx={{ width: "auto", height: "1.25rem" }} />
          )
        }
      />
    </Box>
  );
});

const EmptyResults: React.FC = () => (
  <Box sx={{ textAlign: "center", padding: "1rem" }}>
    <Typography variant="body2">No events were found.</Typography>
  </Box>
);
